import React from 'react';
import { styled } from 'linaria/react';
import Item from './Item';
import { HeroArea } from './HeroArea';
import { MixRow } from './MixRow';
import { theme } from '../../Theming/Theming';

//STYLE VARIABLES
export const startPage = {
  flexSpace: {
    lg: 0,
    md: 0,
    sm: 0
  },
  rowMargin: {
    lg: 0,
    md: 0,
    sm: 0
  }
};

const rowMargin = `
  margin-bottom: ${startPage.rowMargin.lg + '%'};
  ${theme.below.lg} {
    margin-bottom: ${startPage.rowMargin.md + '%'};
  }
  ${theme.below.md} {
    margin-bottom: ${startPage.rowMargin.sm + '%'};
  }
`;

const flexSpaceCalc = (space, amount) => {
  return (100 - space * (amount - 1)) / amount;
};

const StartPageRows = styled('div')`
  display: block;
`;

const Row = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  ${rowMargin};
  &.columns-1 {
    > li {
      width: 100%;
      .image-holder {
        ${theme.below.md} {
          padding-bottom: 100% !important;
        }
      }
    }
  }
  &.columns-2 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 2) + '%'};
    }
  }
  &.columns-3 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 3) + '%'};
    }
  }
  &.columns-4 {
    > li {
      width: ${flexSpaceCalc(startPage.flexSpace.lg, 4) + '%'};
    }
  }
  ${theme.below.sm} {
    &.columns-2,
    &.columns-3 {
      flex-direction: column;
      > li {
        width: 100%;
        ${rowMargin};
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  ${theme.below.md} {
    &.columns-4 {
      > li {
        width: ${flexSpaceCalc(startPage.flexSpace.sm, 2) + '%'};
        &:nth-of-type(-n + 2) {
          ${rowMargin};
        }
      }
    }
  }

  &.row-0 {
    margin-bottom: 0.5rem;
  }
`;

function getClassName(row, index) {
  return 'columns-' + row.items.length + ' row-' + index;
}

function getRows(items) {
  let rows = [];
  items.forEach(item => {
    const index = item.row - 1;
    if (!rows[index])
      rows[index] = {
        row: item.row,
        items: []
      };
    rows[index].items.push(item);
  });
  return rows;
}

function checkRowType(row, type) {
  var item = row.items.filter(item => {
    return item.item.name.search(type) !== -1;
  });
  if (item.length) return true;
  return false;
}

const RowHandler = ({ row, index }) => {
  if (checkRowType(row, '(mixrow)')) {
    return <MixRow row={row} />;
  } else if (checkRowType(row, '(herorow)') && row.items.length > 2)
    return <HeroArea row={row} />;
  else
    return (
      <Row className={getClassName(row, index)}>
        {row.items.map((item, index) => (
          <Item item={item.item} key={index} columns={row.items.length} />
        ))}
      </Row>
    );
};

export const Render = ({ result }) => {
  const { startPage } = result.data;
  if (startPage) {
    const rows = getRows(startPage.items);
    return (
      <StartPageRows>
        {rows.map((row, index) => (
          <RowHandler row={row} key={index} index={index} />
        ))}
      </StartPageRows>
    );
  } else return null;
};
