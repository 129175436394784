import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { ProductCard } from '../../CategoryPage/ProductCard';
import ProductRow from '../ProductRow/ProductRow';
import ParseJson from '../../Util/ParseJson';
import BlogOnPage from '../../Blog/BlogOnPage';
import MaxWidth from '../../Layout/MaxWidth';
import { IconBar } from '../IconBar/IconBar';
import { ItemHtml } from './ItemHtml';

const ItemWrapper = styled('li')`
  display: block;
  position: relative;
`;

function checkType(item) {
  let custom_item = { ...item };
  custom_item.type = 'custom';
  const json = ParseJson(custom_item.content);
  return json.isJson ? { ...custom_item, ...json.content } : custom_item;
}

const Item = ({ item, columns }) => {
  item = checkType(item);
  let element = <Fragment />;
  if (item.__typename === 'Product') {
    element = <ProductCard product={item} />;
  } else {
    switch (item.type) {
      case 'custom':
        element = <ItemHtml item={item} columns={columns} />;
        break;
      case 'list-category':
        element = (
          <MaxWidth>
            <div style={{ margin: '1rem 0' }}>
              <ProductRow {...item} />
            </div>
          </MaxWidth>
        );
        break;
      case 'list-blog':
        element = <BlogOnPage {...item} />;
        break;
      case 'icon-bar':
        element = <IconBar item={item} />;
        break;
      default:
      // Do nothing
    }
  }
  return <ItemWrapper>{element}</ItemWrapper>;
};

export default Item;
