import React, { useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import productRowQuery from './ProductRowQuery.gql';
import { useInView } from 'react-intersection-observer';
import { RowProducts } from './RowProducts';

const ProductRowOuterWrapper = styled('div')`
  width: 100%;
  [data-visible='false'] > div {
    padding-top: 500px;
  }
`;

const ProductRow = ({
  id,
  limit = 6,
  orderBy = null,
  orderByDirection = 'DESCENDING',
  slider = false,
  page,
  title = '',
  subTitle = '',
  ssr = false,
  ...rest
}) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !showed) setShowed(true);
  }, [inView, showed]);

  return (
    <ProductRowOuterWrapper data-visible={showed}>
      <div ref={ref}>
        {showed && (
          <Query
            query={productRowQuery}
            ssr={ssr}
            variables={{
              id: parseInt(id, 10),
              first: parseInt(limit, 10),
              orderBy: orderBy,
              orderByDirection: orderByDirection,
              filters: {
                booleanFilters: [
                  {
                    id: id + ':buyable',
                    value: true
                  }
                ]
              }
            }}
          >
            {result => {
              const category = result && result.data && result.data.category;
              if (
                category &&
                category.products &&
                category.products.result.length > 0
              ) {
                return (
                  <RowProducts
                    category={category}
                    slider={slider}
                    title={title}
                    subTitle={subTitle}
                    {...rest}
                  />
                );
              } else return null;
            }}
          </Query>
        )}
      </div>
    </ProductRowOuterWrapper>
  );
};

export default ProductRow;
