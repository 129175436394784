import React from 'react';
import { styled } from 'linaria/react';
import { GridWrapper } from '../../CategoryPage/ProductGrid';
import { ProductCard } from '../../CategoryPage/ProductCard';
import { ScrollSlider } from '../../ui/ScrollSlider';
import t from '@jetshop/intl';
import {
  ProductListHeader,
  ProductListSubHeader
} from '../../ProductPage/StyledComponents';
import AddToCart from '../../Cart/AddToCart';
import Button from '../../ui/Button';
import { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';

const ProductRowWrapper = styled('div')`
  width: 100%;
`;

const RowProduct = ({ product, quickBuy }) => {
  return (
    <ProductCard product={product} categoryPath={null}>
      {quickBuy &&
        !product.hasVariants &&
        product.stockStatus &&
        product.stockStatus.buyable && (
          <AddToCart product={product} quantity={1} selectedVariation={product}>
            {add => (
              <Button
                css={{ marginTop: '1rem' }}
                buy
                list
                onClick={() => {
                  add();
                }}
              >
                {t('Add to cart')}
              </Button>
            )}
          </AddToCart>
        )}
    </ProductCard>
  );
};

export const RowProducts = ({
  category,
  slider,
  title,
  subTitle,
  quickBuy,
  ...rest
}) => {
  const products =
    (category && category.products && category.products.result) || [];

  const listName = 'product-row: ' + category.name;

  //SKIP SLIDER IF ONLY 2 PRODUCTS
  if (products.length < 3) slider = false;

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    const products = category?.products?.result || [];
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, category, track]);

  return (
    <ProductRowWrapper data-testid="product-grid">
      {subTitle && <ProductListSubHeader>{t(subTitle)}</ProductListSubHeader>}
      {title && <ProductListHeader>{t(title)}</ProductListHeader>}
      {slider ? (
        <ScrollSlider>
          {products.map((product, index) => (
            <RowProduct
              product={product}
              quickBuy={quickBuy}
              key={category.id + '-' + product.id}
            />
          ))}
        </ScrollSlider>
      ) : (
        <GridWrapper>
          {products.map((product, index) => {
            return (
              <RowProduct
                product={product}
                quickBuy={quickBuy}
                key={category.id + '-' + product.id}
              />
            );
          })}
        </GridWrapper>
      )}
    </ProductRowWrapper>
  );
};
