import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import MaxWidth from '../../Layout/MaxWidth';
import { theme } from '../../Theming/Theming';

const IconBarWrapper = styled('div')`
  background: ${theme.startPage.iconBar.background};
  color: ${theme.startPage.iconBar.color};
  padding: 3rem 5px 1rem;
`;

const IconBarInner = styled('div')`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  h3 {
  }
`;

const ImageWrapper = styled(`div`)`
  width: 25px;
  margin-bottom: 0.5rem;
`;

const IconWrapper = styled('div')`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 0.7rem;
`;

export const IconBar = ({ item }) => {
  const { list } = item;
  if (list.length)
    return (
      <IconBarWrapper>
        <MaxWidth>
          <IconBarInner>
            {list.map((listItem, index) => {
              return (
                <IconWrapper key={index}>
                  {listItem.length > 1 && (
                    <ImageWrapper>
                      <Image
                        src={'/pub_images/original/' + listItem[1]}
                        sizes={[50]}
                        aspect={'1:1'}
                      />
                    </ImageWrapper>
                  )}
                  <h5>{listItem[0]}</h5>
                </IconWrapper>
              );
            })}
          </IconBarInner>
        </MaxWidth>
      </IconBarWrapper>
    );
  else return null;
};
