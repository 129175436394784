import React from 'react';
import { ItemHtml } from './ItemHtml';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';

const MixRowWrapper = styled('div')`
  margin: 1% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  ${theme.above.lg} {
    flex-direction: row;
  }
  > div {
    width: 100%;
    .text-box {
      flex-grow: 1;
    }
    ${theme.above.lg} {
      width: 50%;
      &.primary {
        display: flex;
        flex-direction: column;
      }
      &.secondary {
      }
    }
  }
`;

export const MixRow = ({ row, className }) => {
  return (
    <MixRowWrapper className={className}>
      <div className="primary">
        <ItemHtml item={row.items[0].item} aspect="20:10" />
        <ItemHtml item={row.items[1].item} aspect="20:11" />
      </div>
      <div className="secondary">
        <ItemHtml item={row.items[2].item} aspect="10:11" />
      </div>
    </MixRowWrapper>
  );
};
