import React, { Fragment } from 'react';
import { styled } from 'linaria/react';

const backgroundColor = '#EEE';

const MockImage = styled('div')`
  padding-top: 70%;
  background: ${backgroundColor};
`;

const MockProductWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1%;
`;

const MockProduct = styled('div')`
  padding-top: 25%;
  width: 100%;
  background: ${backgroundColor};
  width: 23%;
  margin: 1%;
`;

export default React.memo(function StartPageLoadingState() {
  return (
    <Fragment>
      <MockImage className="full-width" />
      <MockProductWrapper>
        <MockProduct />
        <MockProduct />
        <MockProduct />
        <MockProduct />
      </MockProductWrapper>
    </Fragment>
  );
});
