import React from 'react';
import { ItemHtml } from './ItemHtml';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';

const HeroAreaWrapper = styled('div')`
  margin-bottom: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 100%;
    > * {
    }
    ${theme.below.sm} {
      > * {
        display: block;
        margin-bottom: 3%;
      }
    }
    ${theme.only.sm} {
      &.primary {
        margin-bottom: 2%;
      }
      &.secondary {
        width: 100%;
        display: flex;
        justify-content: space-between;
        > * {
          width: 49%;
        }
      }
    }
    ${theme.above.md} {
      &.primary {
        width: 66.3%;
      }
      &.secondary {
        display: flex;
        width: 32.666%;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;

export const HeroArea = ({ row, className }) => {
  return (
    <HeroAreaWrapper className={className}>
      <div className="primary">
        <ItemHtml item={row.items[0].item} aspect="4:3" />
      </div>
      <div className="secondary">
        <ItemHtml item={row.items[1].item} aspect="400:297" />
        <ItemHtml item={row.items[2].item} aspect="400:297" />
      </div>
    </HeroAreaWrapper>
  );
};
