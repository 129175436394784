import { useQuery } from 'react-apollo';
import blogListQuery from './blogListQuery.gql';

export const useBlog = ({
  id,
  menu,
  pathname,
  limit = false,
  parentId = false
}) => {
  const articleLevel = menu ? 3 : 2;
  const result = useQuery(blogListQuery, {
    variables: { root: id, levels: articleLevel }
  });

  const categories = result?.data?.categories || [];

  //MAIN BLOG CATEGORY
  const mainCategory = categories.length > 0 && categories[0];

  //MENU CATEGORIES
  const menuCategories = menu
    ? categories.filter(cat => cat.level !== articleLevel)
    : false;

  //CURRENT MENU CATEGORY
  const currentCat =
    menu &&
    menuCategories.filter(cat => cat.primaryRoute?.path === pathname)[0];
  const currentMenuCategory = currentCat ? currentCat : mainCategory;

  //ARTICLE CATEGORIES
  const articleList = categories.filter(cat =>
    parentId
      ? cat.parentId === parentId
      : cat.primaryRoute.path.search(pathname) !== -1 &&
        cat.level === articleLevel
  );

  //ARTICLES SORTING
  let articles = articleList.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.mainHeader) - new Date(a.mainHeader);
  });

  //ARTICLES LIMIT
  if (limit > 0) articles = articles.slice(0, limit);

  return {
    mainCategory,
    menuCategories,
    currentMenuCategory,
    articles
  };
};
