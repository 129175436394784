import React from 'react';
import { styled } from 'linaria/react';
import { HallmarkBorder } from '../../ui/General';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theming/Theming';
import { getFontSize } from '../../Theming/helpers';
import { Link } from 'react-router-dom';
import { InlineImage } from '../../ui/InlineImage';

const ItemHTMLWrapper = styled('div')`
  position: relative;

  //HOVER EFFECT
  .item-image {
    position: relative;
    &:before {
      transition: opacity 0.2s;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0;
      z-index: 1;
    }
  }
  &:hover {
    .item-image {
      &:before {
        opacity: 0.1;
      }
    }
  }

  //WRAPPING CLASSES
  &.cat-box {
  }
  &.title {
    margin-top: 2rem;
  }
  &.text-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${theme.startPage.textBox.background};
    color: ${theme.startPage.textBox.color};
    padding: 2rem 1rem;
    justify-content: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0.5em auto;
      max-width: 700px;
      color: ${theme.startPage.textBox.color};
      &:first-child {
        margin-top: 0;
      }
    }
    .read-more {
      display: block;
      margin: 0.5em auto;
      max-width: 700px;
    }
    p {
      margin: 1em auto;
    }
    div.left {
      text-align: left;
    }
  }
`;

const ItemImage = styled('div')`
  display: block;
  overflow: hidden;
`;

const ItemContent = styled('div')`
  display: block;
  text-align: center;
  overflow: hidden;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: ${theme.startPage.headers.textTransform};
  }

  h1 {
    ${getFontSize(theme.startPage.headers.fontSize[1])}
  }
  h2 {
    ${getFontSize(theme.startPage.headers.fontSize[2])}
  }
  h3 {
    ${getFontSize(theme.startPage.headers.fontSize[3])}
  }
  h4 {
    ${getFontSize(theme.startPage.headers.fontSize[4])}
  }
  h5 {
    ${getFontSize(theme.startPage.headers.fontSize[5])}
  }

  video {
    width: 100%;
  }

  //CUSTOM CLASSES TO USE IN ADMIN
  //OVERLAY CLASSES
  .overlay {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 1rem 1rem;
    ${theme.above.md} {
      padding: 2rem 3rem;
    }
    img {
      max-width: 40px;
    }
  }
  .overlay.center {
    width: 100%;
    &.top,
    &.bottom {
      transform: translateX(-50%) translateY(0%);
    }
    &.top {
      top: 5%;
    }
    &.bottom {
      bottom: 5%;
      top: initial;
    }
  }
  .overlay.light {
    > * {
      color: ${theme.colors.white};
    }
  }
  .overlay.left,
  .overlay.right {
    position: absolute;
    text-align: left;
    transform: translateX(0%) translateY(-50%);
    &.top,
    &.bottom {
      transform: translateX(0%) translateY(0%);
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
      top: initial;
    }
  }
  .overlay.right {
    right: 0;
    left: 50%;
    text-align: right;
  }
  .overlay.left {
    left: 0;
  }
  .overlay.center {
    text-align: center;
  }

  //OVERLAY WITH A BORDER
  .box-overlay {
    z-index: 1;
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80%;
    border: 3px solid ${theme.colors.black};
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.light {
      border-color: ${theme.colors.white};
      > * {
        color: ${theme.colors.white};
      }
    }
  }

  //TEXT UNDER IMAGE
  .text-under {
    margin: 2rem 0 3rem;
    padding: 0 2rem;
    p {
      margin: 1em 0;
    }
    &:not(.left) {
      p {
        margin: 1em auto;
        max-width: 400px;
      }
    }
    &.left {
      text-align: left;
    }
  }

  .margin-top {
    margin-top: 3rem;
  }

  .grey-box {
    h2 {
      margin-bottom: 0.5rem;
      margin-top: 0.2rem;
    }
    text-align: center;
    padding: 50px 10px;
    background: ${theme.colors.backgroundLight};
    > p {
      margin: 0 auto 15px;
      max-width: 700px;
    }
  }

  .center {
    text-align: center;
  }

  //CTA BUTTON
  .cta {
    ${getFontSize(theme.startPage.cta.fontSize)};
    border: ${theme.startPage.cta.border};
    color: ${theme.startPage.cta.color};
    background: ${theme.startPage.cta.background};
    font-family: ${theme.startPage.cta.fontFamily};
    font-weight: ${theme.startPage.cta.fontWeight};
    display: inline-block;
    text-align: center;
    text-transform: ${theme.startPage.cta.textTransform};
    letter-spacing: ${theme.startPage.cta.letterSpacing};
    margin-top: 1rem;
    padding: 0.7rem 1rem;
    min-width: 260px;
    transition: all 0.3s;
    margin-top: 1rem;
    ${theme.above.md} {
      margin-top: 3rem;
      padding: 0.9rem 1rem;
    }
    &:hover {
      color: ${theme.startPage.cta.hover.color};
      background: ${theme.startPage.cta.hover.background};
      border: ${theme.startPage.cta.hover.border};
    }
    &.secondary {
      color: ${theme.startPage.cta.secondary.color};
      background: ${theme.startPage.cta.secondary.background};
      border: ${theme.startPage.cta.secondary.border};
      &:hover {
        color: ${theme.startPage.cta.secondary.hover.color};
        background: ${theme.startPage.cta.secondary.hover.background};
        border: ${theme.startPage.cta.secondary.hover.border};
      }
    }
    &.third {
      color: ${theme.startPage.cta.third.color};
      background: ${theme.startPage.cta.third.background};
      border: ${theme.startPage.cta.third.border};
      &:hover {
        color: ${theme.startPage.cta.third.hover.color};
        background: ${theme.startPage.cta.third.hover.background};
        border: ${theme.startPage.cta.third.hover.border};
      }
    }
  }

  //READ MORE BUTTON
  .read-more {
    font-family: ${theme.startPage.readMore.fontFamily};
    font-weight: ${theme.startPage.readMore.fontWeight};
    color: ${theme.startPage.readMore.color};
    text-decoration: underline;
  }

  .title {
    ${HallmarkBorder};
    text-align: center;
    margin: 3% 0;
  }

  .line {
    position: relative;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: -40px;
      top: 45%;
      width: 30px;
      height: 1px;
      background: ${theme.colors.buy};
    }
  }
  .arrow {
    position: relative;
    &:after {
      content: '🡢';
      display: inline-block;
      position: relative;
      font-size: 0.7em;
      margin-left: 2rem;
      transition: all 0.5s;
      left: 0;
      color: ${theme.colors.buy};
    }
  }
  &:hover {
    .arrow {
      &:after {
        left: 3rem;
      }
    }
  }

  .margin {
    margin: 2% 0;
  }

  .icon-bar {
    ul {
      li {
        img {
        }
      }
    }
  }

  .seo-box {
    max-width: 900px;
    padding: 2% 0;
    h5 {
      text-transform: none;
    }
    h5 {
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }
`;

function checkForType(item, text) {
  return item.name && item.name.toLowerCase().search(text) !== -1;
}

function getClass(item) {
  let className = 'item-custom-wrapper';
  if (item.image) className += ' image';
  if (checkForType(item, '(catbox)')) className += '  cat-box';
  if (checkForType(item, '(hero)')) className += '  hero';
  if (checkForType(item, '(title)')) className += '  title';
  if (checkForType(item, '(textbox)')) className += '  text-box';
  return className;
}
function getAspect(columns, item) {
  if (checkForType(item, '(catbox)')) return '30:11';
  if (checkForType(item, '(inlineimage)')) return 'inline';
  if (columns === 1) return '2:1';
  return '1:1';
}
function getSizes(columns) {
  if (columns === 2) return [1, 1, 1 / 2, 1 / 2];
  else if (columns === 3) return [1, 1, 1 / 2, 1 / 3];
  else if (columns === 4) return [1, 1, 1 / 2, 1 / 4];
  return [1, 1, 1, 1];
}

const ItemHtmlContent = ({ item, columns, aspect, sizes, crop }) => {
  const imageAspect = aspect || getAspect(columns, item);
  const imageSizes = sizes || getSizes(columns);
  const imageClassName = !'inline' && 'item-image';
  return (
    <ItemHTMLWrapper className={getClass(item)}>
      {item.image && (
        <ItemImage className={imageClassName}>
          {imageAspect === 'inline' ? (
            <InlineImage
              sizes={imageSizes}
              src={item.image.url}
              alt={item.image.url}
            />
          ) : (
            <Image
              className="image-holder"
              aspect={imageAspect}
              sizes={imageSizes}
              src={item.image.url}
              alt={item.image.url}
              crop={crop || true}
              cover
            />
          )}
        </ItemImage>
      )}
      <ItemContent
        className="item-content"
        dangerouslySetInnerHTML={{
          __html: item.content
        }}
      />
    </ItemHTMLWrapper>
  );
};

export const ItemHtml = ({ item, columns, aspect, sizes, crop }) => {
  if (item.linkUrl)
    return (
      <Link to={item.linkUrl} className={getClass(item)}>
        <ItemHtmlContent
          item={item}
          columns={columns}
          aspect={aspect}
          sizes={sizes}
          crop={crop}
        />
      </Link>
    );
  return (
    <ItemHtmlContent
      item={item}
      columns={columns}
      aspect={aspect}
      sizes={sizes}
      crop={crop}
    />
  );
};
